body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(255, 255, 255);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.customDiv {
    /* border: 5px outset red; */
    text-align: center;
}

.customButton {
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    transition-duration: 0.4s;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.customButton:hover {
    background-color: rgb(64, 102, 146);
    /* Green */
    color: white;
}
.richtext_toolbar{
    display: flex;
    flex-wrap: wrap;
}
.richtext_toolbar:not(:last-child)::after {
    content: "";
    flex-grow: 1;
}