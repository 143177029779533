/* .top {
    margin-bottom: 35px;
    padding: 50px 0;
    position: relative;
    background: #1E438A url(https://www.ask.kim/documentation/wp-content/uploads/2020/10/kim_m_col_rev_rgb_388x166.jpg) left no-repeat;
}

.footer {
    position: fixed !important;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1E438A;
    color: white;
    padding: 12px;
    text-align: center;
} */

.mainContainer {
    font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif;
    background-color: #050a1e;
    background-image: none;
}

.mainContainerWhite {
    font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif;
    background: #e9e9e9;
    background-image: none;
    height: auto !important;
}

a,
[type="submit"] {
    transition: all .25s ease-in;
}

.signup__container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 85vh;
    max-height: 95%;
    min-height: 80%;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

.signup__containerAuth0 {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 50%;
    border-radius: 3px;
}

.signup__containerMob {
    position: absolute;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

.signup__containerMobAuth0 {
    position: absolute;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

.signup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .76);
}

.container__child {
    width: 50%;
    height: 100%;
}

.container__childAuth0 {
    width: 80%;
    height: 100%;
    margin-left: 10%;
    background-color: rgb(5, 10, 30);
}

.signup__thumbnail {
    position: relative;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: url(http://ultraimg.com/images/spectre-login.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.thumbnail__logo,
.thumbnail__content,
.thumbnail__links {
    position: relative;
    z-index: 2;
}

.thumbnail__logo {
    align-self: flex-start;
}

.logo__shape {
    fill: #fff;
}

.logo__text {
    display: inline-block;
    font-size: .8rem;
    font-weight: 700;
    vertical-align: bottom;
}

.thumbnail__content {
    align-self: center;
}

.headerText {
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
}

.heading--primary {
    font-size: 1.999rem;
}

.heading--secondary {
    font-size: 1.414rem;
}

.thumbnail__links {
    align-self: flex-end;
    width: 100%;
}

.thumbnail__links a {
    font-size: 1rem;
    color: #fff;
}

.signup__form {
    padding: 2.5rem;
    padding-right: 10px;
    /*background: #fafafa;*/
    z-index: 0;
}

.signup__formAuth0 {
    padding: 2.5rem;
    padding-right: 5px;
    padding-left: 15px;
    background: #fafafa;
    z-index: 0;
}

.labelText {
    font-size: .85rem;
    color: #ccc;
}

.form-control {
    background-color: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

[type="text"] {
    color: #111;
}

[type="password"] {
    color: #111;
}

.btn--form {
    padding: .5rem 2.5rem;
    font-size: .95rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    background: #111;
    border-radius: 35px;
}

.signup__link {
    font-size: .8rem;
    font-weight: 600;
    text-decoration: underline;
    color: #999;
}

.help__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .76);
}

.help__navbar {
    width: 20%;
    height: 100%;
}

.help__child {
    width: 80%;
    height: 100%;
}

.help__container {
    /* position: absolute; */
    /* top: 50%; */
    /* right: 0;
    left: 0; */
    margin-right: auto;
    margin-left: auto;
    /* transform: translateY(-50%); */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    height: 98%;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

@media (max-width:400px) {
    .help__container {
        width: 100%;
    }
}

.help__thumbnail {
    position: relative;
    padding: 2rem;
    flex-wrap: wrap;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: 100vh !important;
    box-sizing: border-box;
}

.help_thumbnail__content {
    position: relative;
    z-index: 2;
    /* top: 70px; */
}

.help_thumbnail__content_nav {
    position: absolute;
    z-index: 2;
    width: 75%;
    /* top: 200px;
    height: 95%; */
}

.help_ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
    position: relative;
    height: 100%;
    overflow: auto;
}

.help_li {
    display: block;
    color: rgb(255, 255, 255);
    padding: 8px 16px;
    text-decoration: none;
}

.help_li.active {
    background-color: #046daa;
    color: white;
}

.help_li:hover:not(.active) {
    background-color: rgb(144, 186, 235);
    color: white;
}

.dummydeclaration {
    padding-left: 2em;
}


/* Firefox ignores first declaration for some reason */

.tab1 {
    padding-left: 2em;
}

.tab2 {
    padding-left: 4em;
}

.tab3 {
    padding-left: 8em;
}

.tab4 {
    padding-left: 12em;
}

.tab5 {
    padding-left: 16em;
}

.tab6 {
    padding-left: 18em;
}

.tab7 {
    padding-left: 20em;
}

.tab8 {
    padding-left: 22em;
}

.tab9 {
    padding-left: 24em;
}

.tab10 {
    padding-left: 28em;
}

.tab11 {
    padding-left: 30em;
}

.tab12 {
    padding-left: 32em;
}

.scrollVisible::-webkit-scrollbar {
    width: 13px;
    background-color: #F5F5F5;
}

.scrollVisibleXHidden {
    overflow-x: hidden;
}

.scrollVisible::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
    border-radius: 10px;
}

.scrollVisible::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border: #888;
    border-radius: 10px;
}

.scrollVisibleLogs::-webkit-scrollbar {
    background-color: #F5F5F5;
}

.scrollVisibleLogs::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
    border-radius: 10px;
}

.scrollVisibleLogs::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border: #888;
    border-radius: 10px;
}


/* The sidepanel menu */

.sidepanel {
    height: 100%;
    /* Specify a height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 2;
    /* Stay on top */
    top: 0;
    left: 0;
    background-color: #111;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidepanel */
}


/* The sidepanel links */

.sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}


/* When you mouse over the navigation links, change their color */

.sidepanel a:hover {
    color: #f1f1f1;
}


/* Position and style the close button (top right corner) */

.sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


/* Style the button that is used to open the sidepanel */

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    /* Stay on top */
}

.openbtn:hover {
    background-color: #444;
}

h1 {
    /* border: 5px outset red; */
    color: rgb(64, 102, 146);
    left: 0px;
    align-content: center;
    text-align: left;
}

@media (max-height: 390px) and (orientation: landscape) {
    .container__childAuth0 {
        width: 100%;
        /*height: 760px;
        top: 20%; */
    }
    .signup__containerAuth0 {
        position: absolute;
        /* top: 100%; */
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .signup__form {
        padding: 2.5rem;
        padding-right: 10px;
        /* background: #fafafa; */
        z-index: 0;
        height: 100%;
    }
}

@media (min-width: 300px) and (max-width: 448.98px) {
    .signup__containerAuth0 {
        position: absolute;
        top: 54%;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .signup__form {
        padding: 2.5rem;
        padding-right: 10px;
        /* background: #fafafa; */
        z-index: 0;
        height: 90%;
    }
}

@media (min-width: 450px) and (max-width: 767.98px) {
    .signup__containerAuth0 {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto !important;
        border-radius: 3px;
    }
    .signup__containerAuth0 {
        position: absolute;
        /* top: 64%; */
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45%;
        border-radius: 3px;
    }
    .signup__form {
        padding: 2.5rem;
        padding-right: 10px;
        background: #fafafa;
        z-index: 0;
        height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1500.98px) and (orientation: portrait) {
    .signup__containerAuth0 {
        position: absolute;
        top: 64%;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 100%;
        border-radius: 3px;
    }
}

.containerMobile {
    width: 95%;
    height: 110vh;
    background-color: rgb(255, 255, 255);
}

.signup__containerMobile {
    position: relative;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    /* transform: translateY(-50%); */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 100%;
    max-height: 95%;
    min-height: 80%;
    border-radius: 2px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, .25);
}

.DialogBackgoundColor .ms-Overlay {
    background-color: rgba(0,0,0,0);
 }
 .signup__formHelp {
   padding-left: 2rem;
    z-index: 0;
}