@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(255, 255, 255);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.customDiv {
    /* border: 5px outset red; */
    text-align: center;
}

.customButton {
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    transition-duration: 0.4s;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.customButton:hover {
    background-color: rgb(64, 102, 146);
    /* Green */
    color: white;
}
.richtext_toolbar{
    display: flex;
    flex-wrap: wrap;
}
.richtext_toolbar:not(:last-child)::after {
    content: "";
    flex-grow: 1;
}
/* .top {
    margin-bottom: 35px;
    padding: 50px 0;
    position: relative;
    background: #1E438A url(https://www.ask.kim/documentation/wp-content/uploads/2020/10/kim_m_col_rev_rgb_388x166.jpg) left no-repeat;
}

.footer {
    position: fixed !important;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1E438A;
    color: white;
    padding: 12px;
    text-align: center;
} */

.mainContainer {
    font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif;
    background-color: #050a1e;
    background-image: none;
}

.mainContainerWhite {
    font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif;
    background: #e9e9e9;
    background-image: none;
    height: auto !important;
}

a,
[type="submit"] {
    transition: all .25s ease-in;
}

.signup__container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 85vh;
    max-height: 95%;
    min-height: 80%;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

.signup__containerAuth0 {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 50%;
    border-radius: 3px;
}

.signup__containerMob {
    position: absolute;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

.signup__containerMobAuth0 {
    position: absolute;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

.signup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .76);
}

.container__child {
    width: 50%;
    height: 100%;
}

.container__childAuth0 {
    width: 80%;
    height: 100%;
    margin-left: 10%;
    background-color: rgb(5, 10, 30);
}

.signup__thumbnail {
    position: relative;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: url(http://ultraimg.com/images/spectre-login.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.thumbnail__logo,
.thumbnail__content,
.thumbnail__links {
    position: relative;
    z-index: 2;
}

.thumbnail__logo {
    align-self: flex-start;
}

.logo__shape {
    fill: #fff;
}

.logo__text {
    display: inline-block;
    font-size: .8rem;
    font-weight: 700;
    vertical-align: bottom;
}

.thumbnail__content {
    align-self: center;
}

.headerText {
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
}

.heading--primary {
    font-size: 1.999rem;
}

.heading--secondary {
    font-size: 1.414rem;
}

.thumbnail__links {
    align-self: flex-end;
    width: 100%;
}

.thumbnail__links a {
    font-size: 1rem;
    color: #fff;
}

.signup__form {
    padding: 2.5rem;
    padding-right: 10px;
    /*background: #fafafa;*/
    z-index: 0;
}

.signup__formAuth0 {
    padding: 2.5rem;
    padding-right: 5px;
    padding-left: 15px;
    background: #fafafa;
    z-index: 0;
}

.labelText {
    font-size: .85rem;
    color: #ccc;
}

.form-control {
    background-color: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

[type="text"] {
    color: #111;
}

[type="password"] {
    color: #111;
}

.btn--form {
    padding: .5rem 2.5rem;
    font-size: .95rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    background: #111;
    border-radius: 35px;
}

.signup__link {
    font-size: .8rem;
    font-weight: 600;
    text-decoration: underline;
    color: #999;
}

.help__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .76);
}

.help__navbar {
    width: 20%;
    height: 100%;
}

.help__child {
    width: 80%;
    height: 100%;
}

.help__container {
    /* position: absolute; */
    /* top: 50%; */
    /* right: 0;
    left: 0; */
    margin-right: auto;
    margin-left: auto;
    /* transform: translateY(-50%); */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    height: 98%;
    border-radius: 3px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, .25);
}

@media (max-width:400px) {
    .help__container {
        width: 100%;
    }
}

.help__thumbnail {
    position: relative;
    padding: 2rem;
    flex-wrap: wrap;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: 100vh !important;
    box-sizing: border-box;
}

.help_thumbnail__content {
    position: relative;
    z-index: 2;
    /* top: 70px; */
}

.help_thumbnail__content_nav {
    position: absolute;
    z-index: 2;
    width: 75%;
    /* top: 200px;
    height: 95%; */
}

.help_ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
    position: relative;
    height: 100%;
    overflow: auto;
}

.help_li {
    display: block;
    color: rgb(255, 255, 255);
    padding: 8px 16px;
    text-decoration: none;
}

.help_li.active {
    background-color: #046daa;
    color: white;
}

.help_li:hover:not(.active) {
    background-color: rgb(144, 186, 235);
    color: white;
}

.dummydeclaration {
    padding-left: 2em;
}


/* Firefox ignores first declaration for some reason */

.tab1 {
    padding-left: 2em;
}

.tab2 {
    padding-left: 4em;
}

.tab3 {
    padding-left: 8em;
}

.tab4 {
    padding-left: 12em;
}

.tab5 {
    padding-left: 16em;
}

.tab6 {
    padding-left: 18em;
}

.tab7 {
    padding-left: 20em;
}

.tab8 {
    padding-left: 22em;
}

.tab9 {
    padding-left: 24em;
}

.tab10 {
    padding-left: 28em;
}

.tab11 {
    padding-left: 30em;
}

.tab12 {
    padding-left: 32em;
}

.scrollVisible::-webkit-scrollbar {
    width: 13px;
    background-color: #F5F5F5;
}

.scrollVisibleXHidden {
    overflow-x: hidden;
}

.scrollVisible::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
    border-radius: 10px;
}

.scrollVisible::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border: #888;
    border-radius: 10px;
}

.scrollVisibleLogs::-webkit-scrollbar {
    background-color: #F5F5F5;
}

.scrollVisibleLogs::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
    border-radius: 10px;
}

.scrollVisibleLogs::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border: #888;
    border-radius: 10px;
}


/* The sidepanel menu */

.sidepanel {
    height: 100%;
    /* Specify a height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 2;
    /* Stay on top */
    top: 0;
    left: 0;
    background-color: #111;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidepanel */
}


/* The sidepanel links */

.sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}


/* When you mouse over the navigation links, change their color */

.sidepanel a:hover {
    color: #f1f1f1;
}


/* Position and style the close button (top right corner) */

.sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


/* Style the button that is used to open the sidepanel */

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    /* Stay on top */
}

.openbtn:hover {
    background-color: #444;
}

h1 {
    /* border: 5px outset red; */
    color: rgb(64, 102, 146);
    left: 0px;
    align-content: center;
    text-align: left;
}

@media (max-height: 390px) and (orientation: landscape) {
    .container__childAuth0 {
        width: 100%;
        /*height: 760px;
        top: 20%; */
    }
    .signup__containerAuth0 {
        position: absolute;
        /* top: 100%; */
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .signup__form {
        padding: 2.5rem;
        padding-right: 10px;
        /* background: #fafafa; */
        z-index: 0;
        height: 100%;
    }
}

@media (min-width: 300px) and (max-width: 448.98px) {
    .signup__containerAuth0 {
        position: absolute;
        top: 54%;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .signup__form {
        padding: 2.5rem;
        padding-right: 10px;
        /* background: #fafafa; */
        z-index: 0;
        height: 90%;
    }
}

@media (min-width: 450px) and (max-width: 767.98px) {
    .signup__containerAuth0 {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto !important;
        border-radius: 3px;
    }
    .signup__containerAuth0 {
        position: absolute;
        /* top: 64%; */
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45%;
        border-radius: 3px;
    }
    .signup__form {
        padding: 2.5rem;
        padding-right: 10px;
        background: #fafafa;
        z-index: 0;
        height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1500.98px) and (orientation: portrait) {
    .signup__containerAuth0 {
        position: absolute;
        top: 64%;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        transform: translateY(-50%);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 100%;
        border-radius: 3px;
    }
}

.containerMobile {
    width: 95%;
    height: 110vh;
    background-color: rgb(255, 255, 255);
}

.signup__containerMobile {
    position: relative;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    /* transform: translateY(-50%); */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 100%;
    max-height: 95%;
    min-height: 80%;
    border-radius: 2px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, .25);
}

.DialogBackgoundColor .ms-Overlay {
    background-color: rgba(0,0,0,0);
 }
 .signup__formHelp {
   padding-left: 2rem;
    z-index: 0;
}
html {
  scrollbar-width: none;
}

div {
  scrollbar-width: none;
}

h2 {
  /* border: 5px outset red; */
  color: rgb(64, 102, 146);
  left: 0px;
  align-content: center;
  text-align: left;
  font-size: larger;
}

.app-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.user-menu-app {
  white-space: nowrap;
  padding-right: 8px;
  margin-left: 8px;
  flex-shrink: 0;
}
.app-header>div {
  display: flex;
  align-items: center;
  min-width: 0;
}

.app-header>div>div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-header-appname {
  white-space: nowrap !important;
  overflow: visible !important;
}

.app-header-tagline {
  white-space: nowrap;
  min-width: 0;
}

.customPanel {
  background-color: white;
  height: 100%;
  border-right: solid 1px rgb(229, 229, 229);

  /* padding-top: 20px; */
}

.customDiv {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.inlineGroup .ms-ChoiceField {
  display: inline-block;
  margin-left: 10px;
}

.divCentered {
  margin: auto;
}

.divNoWrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.divAlignRigth {
  position: absolute;
  right: 0px;
  top: -5px;
  align-content: center;
  text-align: right;
}

.divAlignRigthNoAbsolute {
  right: 0px;
  align-content: center;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.divAlignLeft {
  position: absolute;
  left: 10px;
  align-content: center;
  text-align: left;
}

.divAlignLeft:active {
  position: absolute;
  left: 0px;
  align-content: center;
  text-align: left;
  font-weight: bold;
}

.divAlignLeftNoAbsolute {
  left: 0px;
  align-content: center;
  text-align: left;
}

.divBoxAlignRigth {
  position: absolute;
  right: 0px;
  top: 5px;
  align-content: center;
  text-align: right;
}

.divProperty {
  right: 0px;
  align-content: center;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: rgb(64, 102, 146);
  font-size: larger;
  padding-top: 15px;
}

.divPropertyValue {
  align-content: center;
  font-family: Calibri;
  font-size: larger;
  padding-top: 15px;
}

.divNotFound {
  align-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding-top: 15px;
}

.divPropertyMedium {
  right: 0px;
  align-content: center;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: rgb(64, 102, 146);
  font-size: 12px;
  padding-top: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.divPropertyValueMedium {
  align-content: center;
  font-family: Calibri;
  font-size: small;
  padding-top: 15px;
}

.divDisabled {
  color: rgb(176, 177, 179);
}

.divBlue {
  color: rgb(64, 102, 146);
}

.divHeaderText {
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  color: rgb(64, 102, 146);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
}

.divHeaderSecondaryText {
  font-family: Calibri;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
}

.divTaskText {
  font-family: Calibri;
  font-size: small;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
  margin-left: 52px;
  margin-bottom: 0px;
  color: grey;
}

::-webkit-scrollbar {
  width: 0px;
}

/* .scrollNoVisible::-webkit-scrollbar {
    width: 0px;
  } */

/* ::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: orange;
    border-radius: 10px;
  }
::-webkit-scrollbar-thumb {
    background: blue;
    border-radius: 10px;
  } */

.scrollVisible {
  overflow-y: auto;
  scrollbar-color: #c7c7c7 #f5f5f5;
  scrollbar-width: thin;
}

.scrollVisibleXHidden {
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .scrollVisible::-webkit-scrollbar {
    width: 13px;
    height: 10px;
    background-color: #f5f5f5;
  }
}

.scrollVisible::-webkit-scrollbar {
  width: 13px;
  height: 10px;
  background-color: #f5f5f5;
}

.scrollVisible::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
  border-radius: 10px;
}

.scrollVisible::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border: #888;
  border-radius: 10px;
}

.scrollVisibleLogs {
  overflow-y: auto;
  scrollbar-color: #c7c7c7 #f5f5f5;
  scrollbar-width: thin;
}

@media only screen and (max-width: 600px) {
  .scrollVisibleLogs::-webkit-scrollbar {
    height: 10px;
    background-color: #f5f5f5;
  }
}

.scrollVisibleLogs::-webkit-scrollbar {
  height: 10px;
  background-color: #f5f5f5;
}

.scrollVisibleLogs::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
  border-radius: 10px;
}

.scrollVisibleLogs::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border: #888;
  border-radius: 10px;
}

.TextFieldParagraph textarea {
  overflow-y: auto;
  scrollbar-color: #c7c7c7 #f5f5f5;
  scrollbar-width: thin;
}

@media only screen and (max-width: 600px) {
  .TextFieldParagraph textarea::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #f5f5f5;
  }
}

.TextFieldParagraph textarea::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #f5f5f5;
}

.TextFieldParagraph textarea::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
  border-radius: 10px;
}

.TextFieldParagraph textarea::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border: #888;
  border-radius: 10px;
}

.DetailsListGrid ::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #f5f5f5;
}

.DetailsListGrid ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
  border-radius: 10px;
}

.DetailsListGrid ::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border: #888;
  border-radius: 10px;
}

.customPersona:hover {
  cursor: pointer;
}

.divHover:hover {
  cursor: pointer;
}

.divCenterMessage {
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  height: 200px;
  text-align: center;
  align-content: stretch;
  padding: 100px 0;
}

.divSearchbar {
  width: "100%";
}

.customDivBorder {
  border: rgb(229, 229, 229);
  border-style: solid;
  border-width: 1px 0px 0px 0px;
}

.divBr {
  margin-bottom: 12px;
}

.divBrSmall {
  margin-bottom: -10px;
}

.fileButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileButton+label {
  font-size: 12px;
  font-family: "Segoe UI";
  color: black;
  display: inline-block;
}

.fileButton:focus+label,
.fileButton+label:hover {
  color: rgb(0, 120, 212);
}

.fileButton+label {
  cursor: pointer;
}

.divDisabled {
  pointer-events: none;
  opacity: 0.7;
}

.invalid {
  background-color: rgb(247, 210, 210);
}

input:focus:invalid {
  background-color: rgb(247, 210, 210);
}

select:focus:invalid {
  background-color: rgb(247, 210, 210);
}

.divToolBoxItem {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 6px 0;
  margin-bottom: 5px;
  text-align: center;
}

.divToolBoxItemUpload {
  background-color: rgb(64, 102, 146);
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 10px 5px;
  text-align: center;
}

.divGridItemSelected {
  background-color: rgb(176, 177, 179);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-color: gray;
  box-shadow: 7px 7px #c1c1c1;
  padding: 4px;
}

.divGridItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(226, 227, 229);
  padding: 4px;
  justify-content: center;
}

.divGridList,
.GridList {
  border: 1px solid rgb(183, 184, 185);
}

.pagination ul {
  display: inline-block;
}

.pagination {
  padding: 0;
}

.pagination li {
  font-size: 12pt;
  color: #0059a3 !important;
  display: inline-block;
  border: solid 1px #0059a3 !important;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  border-collapse: collapse;
  font-weight: 500;
}

.pagination li a {}

.paginationMobile {
  padding: 0;
}

.paginationMobile ul {
  display: inline-block;
  padding: 0;
}

.paginationMobile li {
  font-size: 12pt;
  color: #0059a3 !important;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
  border: solid 1px #0059a3 !important;
  cursor: pointer;
  border-collapse: collapse;
  font-weight: bolder;
}

.active {
  background-color: rgb(64, 102, 146);
}

.active a {
  color: rgb(255, 255, 255);
}

.bodyModalMin {
  width: 30vw;
  height: 29vh;
  flex: 4 4 auto;
  padding: 0 24px 24px 24px;
}

.bodyModalMax {
  width: 30vw;
  height: 42vh;
  flex: 4 4 auto;
  padding: 0 24px 24px 24px;
}

.styled-table {
  border-collapse: collapse;
  margin: 0px 0px;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 70vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: rgb(64, 102, 146);
  color: #ffffff;
  text-align: left;
}

.styled-tableH {
  padding: 12px 15px;
  background: rgb(64, 102, 146);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.styled-tableHWithButton {
  padding-left: 0px;
  padding-right: 5px;
  width: 80px;
  background: rgb(64, 102, 146);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.styled-tableR {
  padding: 12px 15px;
}

.styled-tableC {
  padding: 0px 0px;
  width: 1px;
  color: transparent;
  background: rgb(64, 102, 146);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.styled-table td {
  color: transparent;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

/* .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
} */

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid rgb(64, 102, 146);
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.btn {
  background-color: white;
  border: none;
  color: rgb(64, 102, 146);
  padding: 2px 1px 5px 2px;
  font-size: 14px;
  cursor: pointer;
  width: 25px;
  height: 18px;
  text-align: center;
}

.btn:hover {
  background-color: rgb(64, 102, 146);
  color: white;
}

.columnAlignedRight {
  padding-right: 0px;
  padding-top: 5px;
  text-align: right;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3 !important;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: -100 !important;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item>.react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide>.react-resizable-handle {
  display: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w,
.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n,
.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.w3-input {
  padding: 12px 15px 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 80%;
}

.w3-inputCC {
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  font-weight: normal;
}

.w3-inputCC-datepicker {
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  font-weight: normal;
  width: 100%;
  margin-right: 15px;
}

.w3-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}

.richText {
  min-height: 92%;
  overflow: auto;
  max-height: 92%;
  scrollbar-width: thin;
}

.richText::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.richText::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.3);
  border-radius: 10px;
}

.richText::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border: #888;
  border-radius: 10px;
}

.carousel .control-dots .dot {
  background-color: #0078d4 !important;
}

.videoStyle {
  width: 100%;
}

a.TitleNotes {
  border-bottom: 1px dashed;
  text-decoration: none;
}

a.TitleNotes:hover {
  cursor: help;
  position: relative;
}

a.TitleNotes span {
  display: none;
}

a.TitleNotes:hover span {
  border: #131212 0.2px solid;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  /* background: #f0f5e3e3 no-repeat 100% 5%;*/
  background-color: rgba(253, 253, 250, 0.986);
  left: 0px;
  margin: 10px;
  width: 250px;
  position: absolute;
  top: 2px;
  text-decoration: none;
  color: #131212;
}

.plan {
  display: inline-block;
  margin: 10px 1%;
  font-family: "Lato", Arial, sans-serif;
}

.plan-inner {
  background: #fff;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
}

.entry-title {
  background: #53cfe9;
  height: 140px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.entry-title>h3 {
  background: #20bada;
  font-size: 20px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.entry-title .price {
  position: absolute;
  bottom: -25px;
  background: #20bada;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  /*line-height: 70px;*/
  font-size: 100%;
  /*28px;*/
  font-weight: 700;
}

.price .description {
  /*position: absolute;*/
  font-size: 9px;
  /*bottom: -10px;*/
  left: 25px;
  font-weight: 400;
}

.entry-content {
  color: #323232;
}

.entry-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}

.entry-content li:last-child {
  border: none;
}

.btn {
  padding: 0 0;
  text-align: center;
}

.btn form {
  padding: 0 0;
  text-align: center;
}

.btn form button {
  background: #323232;
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.hot {
  position: absolute;
  top: -7px;
  background: #f80;
  color: #fff;
  text-transform: uppercase;
  z-index: 2;
  padding: 2px 5px;
  font-size: 9px;
  border-radius: 2px;
  right: 10px;
  font-weight: 700;
}

.basic .entry-title {
  background: #75ddd9;
}

.basic .entry-title>h3 {
  background: #44cbc6;
}

.basic .price {
  background: #44cbc6;
}

.standard .entry-title {
  background: #4484c1;
}

.standard .entry-title>h3 {
  background: #3772aa;
}

.standard .price {
  background: #3772aa;
}

.ultimite .entry-title>h3 {
  background: #dd4b5e;
}

.ultimite .entry-title {
  background: #f75c70;
}

.ultimite .price {
  background: #dd4b5e;
}

.ActivateCampaign span {
  color: red !important;
}

.scrollVisibleMobile {
  overflow: auto;
  overflow-x: hidden;
  max-width: 100%;
}

.rpv-core-doc-error-text {
  background-color: #fff !important;
  border-radius: 0.25rem;
  color: #fff;
  line-height: 1.5;
  max-width: 50%;
  padding: 0.5rem;
}

.p-0 {
  padding: unset !important;
}

.m-0 {
  margin: unset !important;
}

.ms-Grid-col .px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.pl-4 {
  padding-left: 16px;
}

.pr-4 {
  padding-right: 16px;
}

.ms-Grid-col .py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pt-4 {
  padding-top: 16px;
}

.pb-4 {
  padding-bottom: 16px;
}

.ms-Grid-col .mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.ms-Grid-col .mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}


.ml-2 {
  margin-left: 8px;
}

.mr-2 {
  margin-right: 8px;
}

.ml-4 {
  margin-left: 16px;
}

.mr-4 {
  margin-right: 16px;
}

.ms-Grid-col .my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mt-2 {
  margin-top: 8px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mb-4 {
  margin-bottom: 16px;
}

.button-na {
  height: 4.5vh !important;
  font-size: 14px;
  font-family: "Segoe UI", "Segoe UI Web(West European)", "Segoe UI", "-apple-system", "BlinkMacSystemFont", "Roboto", "Helvetica Neue", sans-serif;
}

@media (min-width: 1100px) and (max-width: 1400px) {
  .button-na {
    height: 6.5vh !important;
    font-size: 13px !important;
    font-family: "Segoe UI", "Segoe UI Web(West European)", "Segoe UI", "-apple-system", "BlinkMacSystemFont", "Roboto", "Helvetica Neue", sans-serif;
  }
}

.errorMessage-Wizard {
  animation-name: css-0, css-13;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  margin: 0px;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.custom-input {
  border: none;
  padding: 4px 0px 4px 0px;
  background-color: white;
  height: 50px;
}

/* add room for GoToday-button */
.ms-DatePicker-wrap {
  min-height: 245px !important;
}

.ms-DatePicker-goToday {
  top: auto !important;
}

.p-lg {
  padding-left: 15px;
}

.react-grid-layout.form-layout {
  height: auto !important;
}

.react-grid-layout.form-layout .react-grid-item {
  position: initial !important;
  height: auto !important;
  transform: none !important;
}

/* .react-grid-layout.form-layout .react-grid-item.SectionHeader,
.react-grid-layout.form-layout .react-grid-item.Paragraph {
  position: initial !important;
  transform: none !important;
  height: auto !important;
} */

.react-grid-layout.form-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(35px, -webkit-max-content);
  grid-auto-rows: minmax(35px, max-content);
  grid-gap: 15px;
  gap: 15px;
  margin-right: 5px;
  margin-bottom: 20px;
}

.input-form {
  min-height: 20px;
}

.input-form .GridList {
  width: 100%;
}

@media (max-width: 1040px) {
  .react-grid-layout.form-layout {
    grid-gap: 5px;
    gap: 5px;
    grid-gap: 5px;
  }

  .react-grid-layout.form-layout .react-grid-item {
    position: initial !important;
    grid-column: 1 / span 4 !important;
    height: auto !important;
    grid-row: auto !important;
    width: 100% !important;
  }

  .input-form {
    display: block;
    min-height: 30px;
  }
}

.select-input {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background: rgb(255, 255, 255);
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.select-input.readonly {
  background-color: rgb(242, 242, 242);
}

.select-input-value {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 0px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  background: none transparent;
  color: rgb(50, 49, 48);
  width: 100%;
  min-width: 0px;
  outline: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-input-value .select-input-placeholder {
  color: rgb(128, 128, 128);
}

.select-input-buttons {
  display: flex;
}

.ConfigurationTemplateList .ms-GroupSpacer {
  display: inline-block;
  width: 0px !important;
}

.ConfigurationTemplateList .ms-List-surface .ms-List-page {
  margin-left: 15px;
}

.ConfigurationTemplateList .ms-List-surface .ms-List-page .ms-GroupSpacer {
  margin-left: 23px;
}

.pagination span {
  background: transparent;
  color: #0059a3;
  font-weight: bold;
  border: none;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  user-select: none;
}

.pagination li:hover {
  background-color: rgb(243, 242, 241);
}

.pagination li.active:hover {
  background-color: rgb(64, 102, 146)
}

.pagination .active span {
  color: #fff;
}

li.custom-pagination-item.disabled {
  background: rgb(243, 242, 241);
  color: rgb(161, 159, 157) !important;
}

.custom-pagination-item.disabled span {
  color: rgb(161, 159, 157);
}

textarea[disabled],
.richText.disabled {
  background: #f2f2f2;
}

.disabled-file .divToolBoxItemUpload {
  background-color: rgb(243, 242, 241);
  color: rgb(210, 208, 206);
}

.currencyField {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background: rgb(255, 255, 255);
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  border-bottom: 1px solid rgb(204, 204, 204);
  font-size: 14px;
}

.currencyField:hover {
  outline: 0;
}

.currencyField:focus {
  outline: 0;
}

.currencyField:active {
  outline: 0;
}

.currencyField-disabled {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  border-bottom: 1px solid rgb(204, 204, 204);
  background-color: #f3f2f1;
  font-size: 14px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-annotation {
  position: absolute;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-arrow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-left-color: rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-top-color: rgba(0, 0, 0, 0.3);
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 0;
}
.rpv-core-arrow-tl {
  bottom: 0;
  left: 0;
  transform: translate(50%, 50%) rotate(45deg);
}
.rpv-core-arrow-tc {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
}
.rpv-core-arrow-tr {
  bottom: 0;
  right: 0;
  transform: translate(-50%, 50%) rotate(45deg);
}
.rpv-core-arrow-rt {
  left: 0;
  top: 0;
  transform: translate(-50%, 50%) rotate(135deg);
}
.rpv-core-arrow-rc {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(135deg);
}
.rpv-core-arrow-rb {
  bottom: 0;
  left: 0;
  transform: translate(-50%, -50%) rotate(135deg);
}
.rpv-core-arrow-bl {
  left: 0;
  top: 0;
  transform: translate(50%, -50%) rotate(225deg);
}
.rpv-core-arrow-bc {
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%) rotate(225deg);
}
.rpv-core-arrow-br {
  right: 0;
  top: 0;
  transform: translate(-50%, -50%) rotate(225deg);
}
.rpv-core-arrow-lt {
  right: 0;
  top: 0;
  transform: translate(50%, 50%) rotate(315deg);
}
.rpv-core-arrow-lc {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(315deg);
}
.rpv-core-arrow-lb {
  bottom: 0;
  right: 0;
  transform: translate(50%, -50%) rotate(315deg);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-canvas-layer {
  left: 0px;
  position: absolute;
  overflow: hidden;
  top: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-asking-password {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.rpv-core-asking-password-message {
  margin: 8px 0px;
}
.rpv-core-asking-password-input-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rpv-core-asking-password-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 200px;
}
.rpv-core-asking-password-button {
  background-color: #357edd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: transparent;
  color: #ffffff;
  cursor: pointer;
  padding: 8px 16px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
}
.rpv-core-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.rpv-core-button-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-doc-error {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
.rpv-core-doc-error-text {
  background-color: #e53e3e;
  border-radius: 0.25rem;
  color: #fff;
  line-height: 1.5;
  max-width: 50%;
  padding: 0.5rem;
}
.rpv-core-doc-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-icon {
  fill: none;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
  text-align: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-annotation-link a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.rpv-core-annotation-link a:hover {
  background: rgba(255, 255, 0, 0.2);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-menu {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-menu-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 4px 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-modal-body {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 160px auto 0px;
  max-width: 480px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-menu-item {
  align-items: center;
  display: flex;
  padding: 4px 0px;
}
.rpv-core-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.rpv-core-menu-item-icon {
  padding-left: 16px;
  padding-right: 8px;
}
.rpv-core-menu-item-label {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 32px;
  white-space: nowrap;
}
.rpv-core-menu-item-check {
  padding-right: 16px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-inner-pages-vertical {
  display: flex;
  flex-direction: column;
}
.rpv-core-inner-pages-horizontal {
  display: flex;
  flex-direction: row;
}
.rpv-core-inner-pages-wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.rpv-core-inner-page {
  padding: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-page-layer {
  align-items: center;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  margin: 0px auto;
  position: relative;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 9999;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-popover-body {
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  left: 0;
  padding: 8px 0;
  position: absolute;
  top: -9999px;
  z-index: 9999;
}
.rpv-core-popover-body-arrow {
  background: #ffffff;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-annotation-popup-wrapper {
  background-color: #faf089;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 0.75rem;
  margin-left: 0.25rem;
  position: absolute;
  top: 0;
  width: 16rem;
  word-wrap: break-word;
}
.rpv-core-annotation-popup-wrapper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
}
.rpv-core-annotation-popup-wrapper-title {
  font-weight: 600;
  margin-right: 1rem;
}
.rpv-core-annotation-popup-wrapper-content {
  border-top: 1px solid #1a202c;
  padding: 0.25rem;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-primary-button {
  background-color: #357edd;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-popover-overlay {
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-spinner {
  animation-duration: 750ms;
  animation-name: rpv-core-spinner-transform;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition-property: transform;
}
.rpv-core-spinner-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.4);
  stroke-linecap: round;
  stroke-width: 2;
}
@keyframes rpv-core-spinner-transform {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-progress-bar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
}
.rpv-core-progress-bar-inner {
  align-items: center;
  background-color: #357edd;
  border-radius: 9999px;
  color: #ffffff;
  display: flex;
  font-size: 10px;
  justify-content: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-page-size-calculator {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-tooltip-body {
  background: #000;
  border-radius: 4px;
  color: #FFF;
  left: 0;
  max-width: 300px;
  position: absolute;
  text-align: center;
  top: -9999px;
  z-index: 9999;
}
.rpv-core-tooltip-body-arrow {
  background-color: #000;
}
.rpv-core-tooltip-body-content {
  padding: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-core-text-layer {
  height: 100%;
  left: 0px;
  line-height: 1;
  position: absolute;
  top: 0px;
  width: 100%;
}
.rpv-core-text {
  color: transparent;
  cursor: text;
  opacity: 0.2;
  position: absolute;
  transform-origin: 0% 0%;
  white-space: pre;
}
.rpv-core-text::selection {
  background: #0000ff;
  color: transparent;
}
.rpv-core-text-highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-toolbar {
  align-items: center;
  display: flex;
  width: 100%;
}
.rpv-toolbar-left {
  align-items: center;
  display: flex;
}
.rpv-toolbar-center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}
.rpv-toolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.rpv-toolbar-item {
  padding: 0px 2px;
}

/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-thumbnail-container {
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px 0px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  position: relative;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-thumbnail-list-loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.rpv-thumbnail-item {
  cursor: pointer;
  padding: 8px;
}
.rpv-thumbnail-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.rpv-thumbnail-item-selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.container {
  overflow: scroll;
  height: 280px;
  width: inherit;
}

.GridList {
  border-collapse: collapse;
  width: inherit;
}

.GridList th {
  border: 4px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GridList td {
  border: 4px solid white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GridList thead {
  position: -webkit-sticky;
  position: sticky;
  inset-block-start: 0;
  background-color: #f0f0f0;
}

.ms-DatePicker-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.GridList tr:nth-child(even) {
  border: 4px solid #f0f0f0;
  background-color: #f0f0f0;
}

.GridList tbody tr:nth-child(even) td {
  border: 4px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
