/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-toolbar {
  align-items: center;
  display: flex;
  width: 100%;
}
.rpv-toolbar-left {
  align-items: center;
  display: flex;
}
.rpv-toolbar-center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}
.rpv-toolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.rpv-toolbar-item {
  padding: 0px 2px;
}
