.container {
  overflow: scroll;
  height: 280px;
  width: inherit;
}

.GridList {
  border-collapse: collapse;
  width: inherit;
}

.GridList th {
  border: 4px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GridList td {
  border: 4px solid white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GridList thead {
  position: sticky;
  inset-block-start: 0;
  background-color: #f0f0f0;
}

.ms-DatePicker-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.GridList tr:nth-child(even) {
  border: 4px solid #f0f0f0;
  background-color: #f0f0f0;
}

.GridList tbody tr:nth-child(even) td {
  border: 4px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}