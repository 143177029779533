/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-thumbnail-container {
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px 0px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  position: relative;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-thumbnail-list-loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.rpv-thumbnail-item {
  cursor: pointer;
  padding: 8px;
}
.rpv-thumbnail-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.rpv-thumbnail-item-selected {
  background-color: rgba(0, 0, 0, 0.3);
}
